<template>
  <div>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close=false

        :visible="true"
        center
        width="50%"
    >

      <el-form ref="form" inline label-width="6em">
        <el-form-item label="年级">
          <el-input v-model="grade"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
        </el-form-item>
      </el-form>
      <div style="display: flex; justify-content: flex-end">
        <el-button type="primary" @click="addDialog({})">新建</el-button>
        <el-button type="primary" @click="addAllDialog">一键生成</el-button>
      </div>

      <el-table
          :data="tableListView">

        <el-table-column
            label="年级"
            prop="name"
        >
        </el-table-column>
        <el-table-column
            label="班级"
            prop="classNames"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.classNames.toString() }}</div>
          </template>
        </el-table-column>

        <el-table-column
            label="操作"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="updateGrade(scope)">编辑</el-button>
            <el-button type="text" @click="deleteGrade(scope)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>


      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="update()">确 认</el-button>
    <el-button @click="close">取 消</el-button>
  </span>
    </el-dialog>
    <!--新建-->
    <el-dialog
        title='新建'
        :visible="isAddDialog"
        width="30%"
        :show-close="false"
    >

      <el-form ref="form" :model="form" label-width="80px">

        <el-form-item label="年级">
          <el-input v-model.trim="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="班级">
          <el-input v-model.trim="addForm.classNames" onafterpaste="this.value=this.value.replace(/，/g,',')" 
          onkeyup="this.value=this.value.replace(/，/g,',')"></el-input>
        </el-form-item>


      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="cancelAdd">取 消</el-button>
          <el-button type="primary" @click="addGrade">确 定</el-button>
        </span>
    </el-dialog>

    <!--一键生成-->

    <el-dialog
        title="提示"
        :visible="isAddAllDialog"
        width="30%"
        :show-close="false"
    >
      <el-form ref="addAllForm">

        <el-checkbox-group v-model="type" style="margin-bottom: 20px">
          <el-checkbox v-for="(item,index) in checkbox" :key="index" :label="item.label"></el-checkbox>
        </el-checkbox-group>

        <el-form-item label="班级数量" prop="classNumber">
          <el-input v-model="classNumber" style="width: 100px"></el-input>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isAddAllDialog = false">取 消</el-button>
        <el-button type="primary" @click="addAll">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import * as organizationApi from "@/api/gen/organizationApi"

export default {
  name: "gradeClass",
  props: {
    row: {}
  },
  data() {
    return {
      addForm: {
        name: '',
        classNames: '',

      },


      status: "",
      form: {
        schoolId: ''
      },
      type: [],
      isAddDialog: false,
      tableList: [],
      isAddAllDialog: false,

      classNumber: '',
      grade: '',


      checkbox: [
        {label: '幼儿园', index: 0},
        {label: '小学', index: 1},
        {label: '初中', index: 2},
        {label: '高中', index: 3},
      ],


    }
  },

  mounted() {

    this.tableList = this.row.gradeInfos ? this.row.gradeInfos : []

    // if (this.row.gradeInfos) {
    //   for (let item of this.row.gradeInfos) {
    //     let tmp = {
    //       name: item.name,
    //       classNames: item.classNames.join(","),
    //     };
    //     this.form.push(tmp);
    //   }
    // }


  },

  watch: {},

  computed: {

    tableListView() {
      if (!this.grade) {
        return this.tableList;
      } else {

        return this.tableList.filter(item => item.name.includes(this.grade));
      }
    }
  },

  methods: {
    close() {
      this.$emit("close")
    },
    async update() {
      if (this.tableList.length === 0) {
        this.$message.warning('不能为空')
        return
      }

      let clone = this._.cloneDeep(this.tableList)
      clone.forEach(item => {
        let valid = Array.isArray(item.classNames)
        if (!valid) {
          item.classNames = item.classNames.split(",")
        }
      })

      console.log(clone)
      //检查clone中每个对象的name是否有重复
      let nameSet = new Set();
      for (let item of clone) {
        if (nameSet.has(item.name)) {
          this.$message.warning('年级不能重复')
          return
        } else {
          nameSet.add(item.name)
        }
      }

      console.log(clone)
      //检查clone中每个对象的classNames是否有重复
      for (let item of clone) {
        let classNamesSet = new Set();

        for (let name of item.classNames) {
          if (classNamesSet.has(name)) {
            this.$message.warning('班级不能重复')
            return
          } else {
            classNamesSet.add(name)
          }
        }
      }
      await organizationApi.update({gradeInfos: clone, id: this.row.id})
      this.$message.success("添加成功")
      this.$emit("update")
      this.$emit("close");


    },

    async deleteGrage(index) {
      console.log(index)
      let datas = this._.cloneDeep(this.addForm)
      datas.splice(index, 1)
      await organizationApi.update({gradeInfos: datas, id: this.row.id})
    },

    //新建弹框
    addDialog() {

      this.tableList.push({
        name: "",
        classNames: ""
      })

      let x = this.tableList[this.tableList.length - 1];
      this.status = 'add';
      this.addForm = x
      this.isAddDialog = true
    },
    // 新建
    async addGrade() {
      if (!this.addForm.name && !this.addForm.classNames) {
        this.$message.warning('不能为空')
        return
      }

      if (this.status === 'add') {
        this.$message.success('操作成功');
        this.isAddDialog = false
      } else {
        Object.assign(this.tableList[this.backIndex], this.addForm);
        this.$message.success('操作成功');
        this.isAddDialog = false
      }

    },

    //编辑
    async updateGrade(data) {
      this.isAddDialog = true;
      this.back = data.row;
      this.backIndex = data.$index;

      for (const key in data.row) {
        if (Array.isArray(data.row[key])) {
          this.addForm[key] = data.row[key].join(',')
        } else {
          this.addForm[key] = data.row[key]
        }

      }
      this.status = 'edit';


    },

    //删除
    async deleteGrade(data) {
      this.tableList.splice(data.$index, 1);
      this.$message.success('删除成功')


    },


    //一键生成
    addAllDialog() {
      this.isAddAllDialog = true
    },


    cancelAdd() {

      if (this.status === 'add') {


        let obj = this.tableList.pop();

      }


      this.isAddDialog = false;

    },
    addAll() {
      if (this.type.length === 0) {
        this.$message.warning('至少选择一项')
        return
      }
      let tmp = [];
      for (let i = 1; i <= this.classNumber; i++) {
        tmp.push(`${i}班`);
      }

      if (this.type.includes('幼儿园')) {

        this.tableList.push(
            {
              name: '小班',
              classNames: tmp.join(",")
            },
            {
              name: '大班',
              classNames: tmp.join(",")
            },
            {
              name: '中班',
              classNames: tmp.join(",")
            },
        )
      }
      if (this.type.includes('小学')) {

        this.tableList.push(
            {
              name: '一年级',
              classNames: tmp.join(",")
            },
            {
              name: '二年级',
              classNames: tmp.join(",")
            },
            {
              name: '三年级',
              classNames: tmp.join(",")
            },
            {
              name: '四年级',
              classNames: tmp.join(",")
            },
            {
              name: '五年级',
              classNames: tmp.join(",")
            },
            {
              name: '六年级',
              classNames: tmp.join(",")
            }
        )
      }
      if (this.type.includes('初中')) {


        this.tableList.push(
            {
              name: '初一',
              classNames: tmp.join(",")

            },
            {
              name: '初二',
              classNames: tmp.join(",")

            },
            {
              name: '初三',
              classNames: tmp.join(",")

            }
        )


      }
      if (this.type.includes('高中')) {


        this.tableList.push(
            {
              name: '高一',
              classNames: tmp.join(",")

            },
            {
              name: '高二',
              classNames: tmp.join(",")

            },
            {
              name: '高三',
              classNames: tmp.join(",")

            }
        )

      }


      console.log(this.tableList)
      // let arr = []
      // for (let item in clone) {
      //   let obj = {
      //     name: item,
      //     classNames:
      //         clone[item].map(x => {
      //
      //           return x.classNames
      //         })
      //   }
      //   arr.push(obj)
      // }
      // this.tableList = arr
      this.isAddAllDialog = false

    },
    //搜索
    search() {

    }
  }
}
</script>

<style scoped>

/deep/ .el-form-item__label {

  padding-left: 10px;
  text-align: left;

}


</style>
