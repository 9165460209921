<template>
  <el-card class="box-card" style="margin: 20px;">
   
    <div style="display: flex; align-items: center; justify-content: space-between">
      <div style="display: flex; align-items: center">
        <input id="file" type="file" style="display: none" @change="handleChange($event)">
        <el-button type="primary" style="margin-left: 10px;" @click="handleImport">数据导入</el-button>
        <div style="color: #ff5733; font-size: 14px; margin-left: 10px;margin-right:20px">* 导入前请先在学校管理模块创建学校名称、年级、班级</div>
        <a href="https://haoyong2.oss-cn-shenzhen.aliyuncs.com/template.xlsx"> 模板下载</a>
      </div>
      <div>
          <!-- <el-button type="primary" @click="submit">上传</el-button> -->
      </div>
    </div>

    <el-table
        :data="tableData"
        style="width: 100%"
        size="mini"
    >
      <el-table-column
          prop="学校"
          label="学校"
          align="center">
        <template slot-scope="scope">

          <span :class="{'red':scope.row['学校'] === '必填项'||scope.row['学校'].indexOf('不存在')>-1}">
            {{ scope.row['学校'] }}
          </span>

        </template>
      </el-table-column>
      <el-table-column
          prop="年级"
          label="年级"
          align="center">
        <template slot-scope="scope">

          <span :class="{'red':scope.row['年级'] === '必填项'||scope.row['年级'].indexOf('不存在')>-1}">
            {{ scope.row['年级'] }}
          </span>

        </template>
      </el-table-column>
      <el-table-column
          prop="班级"
          label="班级"
          align="center">
        <template slot-scope="scope">

          <span :class="{'red':scope.row['班级'] === '必填项'||scope.row['班级'].indexOf('不存在')>-1}">
            {{ scope.row['班级'] }}
          </span>

        </template>
      </el-table-column>
      <el-table-column
          prop="姓名"
          label="姓名"
          align="center">
        <template slot-scope="scope">

          <span :class="{'red':scope.row['姓名'] === '必填项'}">
            {{ scope.row['姓名'] }}
          </span>

        </template>
      </el-table-column>
      <el-table-column
          prop="性别"
          label="性别"
          align="center">
        <template slot-scope="scope">

          <span :class="{'red':scope.row['性别'] === '必填项'}">
            {{ scope.row['性别'] }}
          </span>

        </template>
      </el-table-column>
      <el-table-column
          prop="民族"
          label="民族"
          align="center">
        <template slot-scope="scope">

          <span :class="{'red':scope.row['民族']=== '必填项'}">
            {{ scope.row['民族'] }}
          </span>

        </template>
      </el-table-column>
      <el-table-column
          prop="学号"
          label="学号"
          align="center">
        <template slot-scope="scope">

          <span :class="{'red':scope.row['学号']=== '必填项'}">
            {{ scope.row['学号'] }}
          </span>

        </template>
      </el-table-column>
      <el-table-column
          prop="身份证"
          label="身份证"
          align="center">
        <template slot-scope="scope">

          <span :class="{'red':scope.row['身份证'] === '必填项'}">
            {{ scope.row['身份证'] }}
          </span>

        </template>
      </el-table-column>
      <el-table-column
          prop="出生年月日"
          label="出生年月日"
          align="center">
        <template slot-scope="scope">

          <span :class="{'red':scope.row['出生年月日']=== '必填项'}">
            {{ scope.row['出生年月日'] }}
          </span>

        </template>
      </el-table-column>
      <el-table-column
          prop="联系电话"
          label="联系电话"
          align="center">
        <template slot-scope="scope">

          <span :class="{'red':scope.row['联系电话']=== '必填项'}">
            {{ scope.row['联系电话'] }}
          </span>

        </template>
      </el-table-column>
      <el-table-column
          prop="检测日期"
          label="检测日期"
          align="center">
        <template slot-scope="scope">

          <span :class="{'red':scope.row['检测日期']=== '必填项'}">
            {{ scope.row['检测日期'] }}
          </span>

        </template>
      </el-table-column>
      <el-table-column
          prop="体检年度"
          label="体检年度"
          align="center">
        <template slot-scope="scope">

          <span :class="{'red':scope.row['体检年度']=== '必填项'}">
            {{ scope.row['体检年度'] }}
          </span>

        </template>
      </el-table-column>
      <el-table-column
          prop="年龄"
          label="年龄"
          align="center">
        <template slot-scope="scope">

          <span :class="{'red': scope.row['年龄']=== '必填项' }">
            {{ scope.row['年龄'] }}
          </span>

        </template>
      </el-table-column>
      <el-table-column
          prop="体检年度"
          label="城乡"
          align="center">
        <template slot-scope="scope">

          <span :class="{'red':scope.row['体检年度']=== '必填项'}">
            {{ scope.row['体检年度'] }}
          </span>

        </template>
      </el-table-column>

    </el-table>
  </el-card>
</template>

<script>
import { DateTime } from "luxon";

import ZxxSchoolSelect from '@/components/ZxxSchoolSelect'
import * as medicalInformationApi from '@/api/gen/medicalInformationApi'
import * as organizationCheckItemStatusApi from "@/api/gen/organizationCheckItemStatusApi"
import * as organizationApi from "@/api/gen/organizationApi"

export default {
  name: "zxxStudentData",
  components: {
    ZxxSchoolSelect
  },
  data() {
    return {
      schoolsId:[],
      schools:[],
      form: {
        organizationId: ''
      },
      tableData: [],
      allData: [],
      tableHeader: [
        {code: "year", name: '体检年度'},
        {code: "birthDate", name: '出生年月日'},
        {code: "cxType", name: '城乡'},
        {code: "name", name: '姓名'},
        {code: "studentId", name: '学号'},
        {code: "schoolId", name: '学校'},
        {code: "grade", name: '年级'},
        {code: "age", name: '年龄'},
        {code: "sex", name: '性别'},
        {code: "checkDate", name: '检测日期'},
        {code: "nationalityCode", name: '民族'},
        {code: "classRoomNo", name: '班级'},
        {code: "idNo", name: '身份证'},
        {code: "contact", name: '联系电话'},
      ],


      allSchool: []
    }
  },
  computed: {
    actionPath() {
      return process.env.VUE_APP_API + "/file/upload";
    },
  },
  async mounted(){
        let res = (await organizationApi.getById({id:this.$store.state.userInfo.user.organizationId}))
        console.log(res);
        
        this.schoolsId= res.schools
        this.schools = (await organizationApi.list({id: this._.chain(res.schools).join(",").value(),pageSize:10000})).data
  },
  methods: {
    readFile(file) {
      return new Promise(resolve => {
        let reader = new FileReader();
        reader.readAsBinaryString(file)
        reader.onload = res => {
          resolve(res.target.result)
        }
      })
    },
    handleImport() {
       document.getElementById('file').value=""
      document.getElementById('file').click()
    },

    findSch(sch){
      if(sch){
        var matchedSch = this.schools.filter(s=>s.name==sch.trim());
        if(matchedSch.length==0){
          return null
        }else{
          return matchedSch[0]
        }
      }
    },

     excelDateToJSDate(excelDate) {  
    // Excel的基准日期是1900年1月1日  
    const excelBaseDate = new Date(1900, 0, 1); // 月份从0开始，所以1月是0  
    // Excel日期是从1900年1月1日开始的序列号，计算实际日期  
    const jsDate = new Date(excelBaseDate.getTime() + (excelDate - 1) * 86400000);  
    return jsDate;  
}  ,

// 输出对应的JavaScript日期

    async handleChange(ev) {
      console.log(ev)
      let file = ev.target.files[0]
      if (!file) {
        return
      }

      let data = await this.readFile(file)
      let workbook = this.$XLSX.read(data, {type: 'binary'})
      let workSheet = workbook.Sheets[workbook.SheetNames[0]];



      console.log("----------------------")
      console.log(workSheet)
      data = this.$XLSX.utils.sheet_to_json(workSheet, {range: 1});


      let tmp = this.tableHeader.map(y => y.name)
      let arr = []
      var hasInvalidData =0;
      for (let i = 0; i < data.length; i++) {
        var valid=true;
        console.log(data[i]);
        
        for (let k of tmp) {



          if(data[i][k]&&this._.isString(data[i][k])){
            data[i][k] = data[i][k].trim()
            var checkDate =  data[i][k]
            if(k=='检测日期'||k=='出生年月日'){
                var d = new Date(checkDate)
                console.log(d);   
                console.log(k+"生日检查"+ this._.isDate(d));
                var dateStr=this.$dateString(new Date(checkDate));
                 console.log(dateStr);
                if(!this._.isDate(d)||"Invalid DateTime"==dateStr){
                  valid =false
                  data[i][k] += '【日期格式不对】'
                }
            }
          }

          if(data[i][k]&&this._.isNumber(data[i][k])){
            if(k=='检测日期'||k=='出生年月日'){
              //data[i][k] 是excel中的日期格式的数字,将其转换为js的日期格式
              let dd =data[i][k]
              const jsDate = this.excelDateToJSDate(dd);  


             
              console.log("d:"+jsDate);
              //检查d是否早于2000-01-01
              if(jsDate<new Date("2000-01-01")){
                valid =false
                data[i][k] = this.$dateString(jsDate)+'【日期早于2000-01-01】，请检查出生年月是否正确'
              }
            }
          }
          
          if (!data[i][k] && k!='联系电话'&&k!='身份证'&&k!='学号'&&k!='年龄'&&k!='检测日期'&&k!='民族') {
            data[i][k] = '必填项'
            valid =false
          }
          
          if(k=='学校'){
              var sch = data[i][k]
              var matchedSch = this.findSch(sch);
              if(matchedSch){
                 var grade = data[i]['年级']
                 var matchgrade = matchedSch.gradeInfos.filter(c=>c.name==grade)
                 if(matchgrade.length>0){
                    var cls = data[i]['班级']
                    var matchCls = matchgrade[0].classNames.filter(c=>c==cls)
                    if(matchCls.length==0){
                      data[i]['班级'] += '【班级不存在】'
                      valid =false
                    }
                 }else{
                    data[i]['年级'] += '【年级不存在】'
                    valid =false
                 }
              }else{
                data[i][k] += '【学校不存在】'
                valid =false
                
              }
          }
        }
        if(!valid){
          arr.push(data[i])
          hasInvalidData++; 
        }

      }

      this.tableData = Array.from(new Set(arr))
      this.allData = data
      if (hasInvalidData==0) {

         await this.submit()
        // this.$message.success('导入成功,无异常数据')
      } else {
        this.$message.warning(`有${hasInvalidData}条异常数据，请修正后重新导入`)
      }
    },


    async submit() {
      if (!(this.allData !== null && this.allData.length !== 0)) {
        this.$message.warning('没有数据')
        return
      }
      // if (this.tableData.length > 0) {
      //   this.$message.warning('有异常数据未处理')
      //   return
      // }

      let arr = []
      let clone = this._.cloneDeep(this.allData)

      for (let i = 0; i < clone.length; i++) {
        let obj = {}
        for (let k of this.tableHeader) {
          obj[k.code] = clone[i][k.name]
        }
        arr.push(obj)
      }

      const statusRes = (await organizationCheckItemStatusApi.list({
        organizationId: this.$store.getters.organizationId,
        pageSize: 1000000
      }));

      var optionsList = this._.chain(statusRes.data).filter(item => item.checkItemVO?.types?.includes(1)).sortBy(x => parseFloat(x.sortKey)).value()

      let status = "";

      for (let i = 0; i < 100; i++) {

        let code = ("p" + (i + "").padStart(3, "0"));
        // console.log(code);

        if (optionsList.filter(x => x.checkItemVO.code === code)?.pop()?.status === 1) {
          status += "1";
        } else {
          status += "0";
        }
      }

      arr.forEach(item => {

        console.log("生日："+item['birthDate']);
        console.log("检测日期"+item['checkDate']);
        var nationalityCode = item['nationalityCode'];
        if(!nationalityCode){
            item['nationalityCode']="汉族"
        }
        var checkDate = item['checkDate'];
        if(!checkDate){
            item['checkDate']=this.$dateString(new Date())+" 00:00:00"
        }else   if(this._.isString(checkDate)){
            // item['checkDate']=this.$dateString(new Date())
          item['checkDate'] = this.$dateString(new Date(checkDate))+" 00:00:00"
        }else{
           console.log(checkDate);

          var start =   new Date('1900/1/1');
          start.setHours(0);
          start.setMinutes(0);
          start.setSeconds(0)
          var d = start.getTime()
        
          d= d +(checkDate-1)*24*60*60*1000
          checkDate = new Date()
          checkDate.setTime(d)
          console.log("转换：" +d+"  "+checkDate);
          
          item['checkDate'] =  this.$dateString(checkDate)+" 00:00:00"
        }

        item['schoolId'] = this.schools.filter(school => school.name === item['schoolId']).pop()?.id || ''
        var birth = item['birthDate'];
        if(this._.isString(birth)){
          var dateStr=this.$dateString(new Date(birth));
          console.log(dateStr);
                    item['birthDate'] = dateStr+" 00:00:00"
        }
        
        else{
          console.log(birth);
          
         var start =   new Date('1900/1/1');
         start.setHours(0);
         start.setMinutes(0);
         start.setSeconds(0)
         var d = start.getTime()
        
          d= d +(birth-1)*24*60*60*1000
          birth = new Date()
          birth.setTime(d)
          console.log("转换：" +d+"  "+birth);
          
          item['birthDate'] =  this.$dateString(birth)+" 00:00:00"
        }
        
        item['cxType'] = item['cxType'] === '城市' ? '0' : '1'
        item['sex'] = item['sex'] === '男' ? '0' : '1'
        item['checkItemStatus'] = status
        // item['studentId'] = ""
        // item['idNo'] = ""
        // item['contact'] = ""
        item['nationalityCode'] = this.$store.state.nationalityList.filter(x => x.name === item['nationalityCode']).pop()?.code
      })

      let res = await medicalInformationApi.importFromExcel2({data: arr})
      this.$message.success('上传成功'+arr.length+"条数据")
    },

    initDone(data) {
      this.allSchool = data
    }
  }
}
</script>

<style scoped>
.red {
  color: red;
}
</style>
